<template>
	<main>
		<el-dialog :visible="true" @close="close">
			<template slot="title">
				<div class="title">{{query.id ? '编辑' : '创建'}}{{ title }}</div>
			</template>
			<div class="form-box">
				<el-form class="create-form" ref="formRef" :rules="rules" :model="form" label-width="100px">
					<div class="form-flex">
						<el-form-item label="工单号" prop="sn" v-if="query.id">
							<el-input v-model="form.sn" />
						</el-form-item>
						<el-form-item label="类型" prop="type">
							<el-select class="el-block" v-model="form.type" >
								<el-option :value="item.id" v-for="(item,index) in column_option.type" :key="index" :label="item.title"></el-option>
							</el-select>
						</el-form-item>
					</div>
					<div class="form-flex">
						<el-form-item label="设备" prop="good_id">
							<form-api v-model="form.good_id" :api="good.all" />
						</el-form-item>
						<el-form-item label="部门" prop="department_id" v-if="query.id">
							<form-api v-model="form.department_id" :api="department.all" />
						</el-form-item>
					</div>
					<template v-if="form.type === 1">
						<el-form-item label="图片" prop="image">
							<form-upload v-model="form.image" :limit="9" />
						</el-form-item>
						<el-form-item label="视频" prop="video">
							<form-upload v-model="form.video" accept="video" />
						</el-form-item>
					</template>
					<el-form-item label="说明" prop="message">
						<form-editor v-model="form.message" />
					</el-form-item>
					<template v-if="query.id">
						<div class="form-flex">
							<el-form-item label="创建人" prop="creator_id">
								<form-api v-model="form.creator_id" :api="user.all" :query="{role:'2,3'}" title="username"  placeholder=""/>
							</el-form-item>
							<el-form-item label="创建时间" prop="create_time">
								<form-date-picker v-model="form.create_time" type="datetime" />
							</el-form-item>
						</div>
						<template v-if="form.status >= 2">
							<div class="form-flex">
								<el-form-item label="处理部门" prop="check_department_id">
									<form-api v-model="form.check_department_id" :api="department.all" />
								</el-form-item>
								<el-form-item label="处理人" prop="creator_id">
									<form-api v-model="form.check_id" :api="user.all" :query="{role:'2,3'}" title="username"  placeholder=""/>
								</el-form-item>
							</div>
						</template>
						<template v-if="form.status >= 3">
							<el-form-item label="处理图片" v-if="form.check_image" prop="check_image">
								<form-upload v-model="form.check_image" :limit="9" />
							</el-form-item>
							<el-form-item label="处理视频" v-if="form.check_video" prop="check_video">
								<form-upload v-model="form.check_video" accept="video" />
							</el-form-item>
							<el-form-item label="处理说明" prop="check_message">
								<el-input type="textarea" v-model="form.check_message" />
							</el-form-item>
							<template v-if="form.type === 3">
								<el-form-item label="巡检状态" prop="check_status">
									<el-radio-group v-model="form.check_status" >
										<el-radio v-for="(item,index) in column_option.check_status" :key="index" :label="item.id">{{item.title}}</el-radio>
									</el-radio-group>
								</el-form-item>
							</template>
							<el-form-item label="处理时间" prop="create_time">
								<form-date-picker v-model="form.complete_time" type="datetime" />
							</el-form-item>
						</template>
						<div class="form-flex">
							<el-form-item label="状态" prop="check_status">
								<el-radio-group v-model="form.status" disabled>
									<el-radio v-for="(item,index) in column_option.status" :key="index" :label="item.id">{{item.title}}</el-radio>
								</el-radio-group>
							</el-form-item>
						</div>
					</template>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close">取 消</el-button>
				<el-button type="primary" :loadin="submit_loading" @click="submit">保 存</el-button>
			</div>
		</el-dialog>
	</main>
</template>
<script>
import {task as api, good, department, user} from "@/api/admin";
import {MixinCreate} from "@/service/admin/mixinCreate";
export default {
	mixins:[MixinCreate],
	data(){
		return {
			api,
			department,
			good,
			user,
		}
	},
	computed:{
		rules(){
			return {
				type:[
					{required:true,message:"请选择类型"}
				],
				good_id:[
					{required:true,message:"请选择设备"}
				],
				message:[
					{required:true,message:"请输入说明"}
				],
			}
		}
	},
}
</script>
