<template>
	<main>
		<div class="el-content">
			<table-search v-model="search_data" :column="column" @clean="clean" @search="search"/>
			<div class="action-box">
				<div class="action">
					<el-button class="el-icon-refresh" @click="all"></el-button>
					<el-button class="el-icon-plus" @click="create">创建</el-button>
					<el-popconfirm title="确定要删除选中的数据吗？" @confirm="deleteChoose">
						<template #reference>
							<el-button icon="el-icon-delete">删除</el-button>
						</template>
					</el-popconfirm>
					<template v-if="import_visible">
						<el-button class="el-icon-download" @click="templateFile">下载导入模板</el-button>
						<el-button class="upload-btn el-icon-upload" :loading="upload_loading">批量导入
							<input type="file" id="file" @change="uploadFile" accept=".xlsx">
						</el-button>
					</template>
				</div>
				<div class="other">
					<table-column :column="column" @change="changeColumn"/>
					<el-button v-if="export_visible" class="el-icon-download" @click="exportAll" :loading="export_loading" title="导出"></el-button>
				</div>
			</div>
			<div class="table-box">
				<table-table :table_loading="table_loading" :table_data="table_data" :column_visible="column_visible" :column="column" @selection="selection" @sortChange="sortChange" @changeSort="changeSort">
					<template #column="{scope,item}">
						<div v-if="item.type === 'creator'">{{creatorText(scope)}}</div>
						<div v-if="item.type === 'check'">{{checkText(scope)}}</div>
					</template>
					<template #action="{scope}">
						<div class="el-link-group">
							<el-link type="primary" @click="editor(scope.row)">详情</el-link>
							<el-popconfirm title="确定要删除该数据吗？" @confirm="deleted(scope.row)">
								<template #reference>
									<el-link type="primary">删除</el-link>
								</template>
							</el-popconfirm>
						</div>
					</template>
				</table-table>
			</div>
			<div class="page-box">
				<el-pagination @size-change="sizeChange" @current-change="currentChange" :current-page="page_info.page" :page-sizes="[10, 15, 20, 50,100]" :page-size="page_info.limit" layout="total,sizes,prev,pager,next,jumper" :total="page_info.total" hide-on-single-page background></el-pagination>
			</div>
		</div>
		<table-import v-if="import_visible" ref="importRef" :import_field="import_field" @saveAll="saveAll"/>
		<components-create v-if="create_visible" ref="createRef" :title="title" :column_option="column_option" @success="all" @close="closeCreate"/>
	</main>
</template>
<script>
import ComponentsCreate from "./Create.vue"
import {MixinIndex} from "@/service/admin/mixinIndex";
import { good,department,task as api} from "@/api/admin";

export default {
	components: {ComponentsCreate},
	mixins: [MixinIndex],
	data() {
		return {
			api,
			title: "任务",
			column_option: {
				type: [{id: 1, title: "维修"}, {id: 2, title: "保养"}, {id: 3, title: "巡检"}],
				check_status: [{id: 1, title: "正常"}, {id: 2, title: "异常"}],
				status: [{id: 1, title: "待派单"}, {id: 2, title: "已派单"}, {id: 3, title: "已处理"}],
			},
		}
	},
	computed: {
		column() {
			return [
				{label: "ID", prop: "id",  sortable: true},
				{label: "工单号", prop: "sn", search: true},
				{label: "类型", prop: "type", type: "option", search: true, option: this.column_option.type},
				{label: "设备名称", prop: "good.title", search: true,type:'api',option:{api:good.all,field:'good_id'}},
				{label: "设备编号", prop: "good.code"},
				{label: "部门", prop: "department.title", search: true,type:'api',option:{api:department.all,field:'department_id'}},
				{label: "创建人", prop: "creator",type:"creator"},
				{label: "创建时间", prop: "create_time", type: 'datetime', sortable: true},
				{label: "处理部门", prop: "check_department.title", search: true,type:'api',option:{api:department.all,field:'check_departments_id'}},
				{label: "处理人", prop: "check",type:"check"},
				{label: "处理时间", prop: "complete_time", type: 'datetime', sortable: true},
				{label: "状态", prop: "status", type: "option", search: true, option: this.column_option.status},
				{label: "操作", prop: "action", fixed: 'right', type: 'action'},
			]
		}
	},
	methods:{
		creatorText(scope){
			return scope?.row?.creator?.username ? scope?.row?.creator?.username+`（${scope.row.creator.code}）` : '管理员'
		},
		checkText(scope){
			return scope?.row?.checker?.username || '-';
		}
	}
}
</script>
